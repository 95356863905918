import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg transp'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is</span> WazirX Clone Script?</h2>
              </div>
            </div>

            <div className="text-center">
              <p className="pharagraph" >WazirX clone script is a 100% ready-to-launch, P2P cryptocurrency exchange script that functions exactly like the WazirX crypto exchange. Our script has a complete set of features that existing exchange has along with some latest trading features. By using our script, you can launch a fully functional crypto trading platform within a few days as per your business concepts. Our pre-designed software has a breathtaking User interface which helps in attracting a huge fan base. It is user-friendly and has a great running speed.
              </p>
              <p className="pharagraph mb-0">Our pre built software solution comes with a high scalability feature and the website can run without interruptions. We at Coinsclone are ready to customize the software as per your business requirements. Just knock on the doors of Coinsclone and get our premium white label software right now!
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis