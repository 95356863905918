import React from 'react'




class QuickviewSection extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="quick mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Quick View</span> of</span>
              Our WazirX Clone Script</h2>
          </div>
          <div className="text-center">
            {/* <img width="1137px" height="622px" src={video} alt="quick view" /> */}
            <video className="lazyload" width="1137" height="622" controls preload={true}  muted={true} playsinline>
              <source src="https://coinsclone.mo.cloudinary.net/images/wzrx/wazirxvideo.mp4?cld-content-marker=jit" type="video/mp4"></source>
            </video>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default QuickviewSection