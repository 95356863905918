import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <section className="banner  casestudy">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>Successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/zedxion.webp"
                    alt="Canapay Logo"
                    width={300}
                    className='mb-4'
                />
                <ul>
                    <li>Zedxion utilized our WazirX Clone script to build a fantastic crypto exchange with trading features like Spot, Future, and Margin.</li>
                    <li>The platform generated a <b>Whopping $3.56M trade volume</b> in 24 hours along with 10k downloads in 6 months period of time.</li>
                    <li>More than 90,000 users have visited in the last 6 months, which sums up the impact produced by the platform in the market.</li>
                    <li>The Zedxion token we created, had also price erupted to $0.00000007695 from $0.16 with a massive <b>ROI of 215815379.58%</b>.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/zedxion-casestudy.webp"
                alt="Zedzion"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Get a free demo of our Wazirx clone script</a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy