import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">WazirX</span> Clone Script</h1>
                <p className="sub-heading">Develop a first-class cryptocurrency exchange like WazirX with our customizable WazirX Clone Script.
                </p>
                <ul>
                  <li className="pharagraph">Easy-to-handle crypto exchange script
                  </li>
                  <li className="pharagraph">Our Script is designed & developed with well-experienced developers
                  </li>
                  <li className="pharagraph">Our script comes with robust security features
                  </li>
                </ul>
                <div className="banner-btn">
                  <ButtonStartfree />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/header-with-lap-mockup.png" alt="Order Book Exchange Software Front View" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/wazirx-clone-script.png" alt="Trading View of Orderbook Exchange software" />
                    <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/wazirx-clone.png" alt="Our script comes with robust security features" />
                    <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/wazirx.png" alt="Admin Panel of order Book Cryto Exchange Software" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner