import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> we integrate in </span>
             WazirX Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Stunning User Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Powerful Admin Panel
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Crypto Wallet Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  KYC Verification
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  SMS Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Multiple Payment Methods
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Multiple Language Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Online/Offline Trading
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  User Messaging System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Dispute Management System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Affiliate System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Support System
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Stunning User Dashboard
                        </h4>
                        <p className="pharagraph">Our software has an outstanding and user-friendly dashboard so that your users can experience the best trading experience on the platform.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="392px" height="261px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/stunning-dashboard.png" alt="Stunning User Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Powerful Admin Panel
                        </h4>
                        <p className="pharagraph">The administration panel helps the admin of the exchange to manage all the functions easily. Admin can control all the security aspects, commission fees, and other essential functions in a trouble-free manner.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/powerful-admin-panel.png" alt="Powerful Admin Panel" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Crypto Wallet Integration
                        </h4>
                        <p className="pharagraph">Our integrated Cryptocurrency wallet in the crypto exchange script could support more than 100+ Crypto coins for the trading process. Your users can send, receive, and store various digital coins securely in the wallet. We implement a Multi-Signature wallet security function to prevent hacking and other fraudulent activities.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="358px" height="317px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/crypto-wallet-integration.png" alt="Crypto Wallet Integration" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC Verification
                        </h4>
                        <p className="pharagraph">For security purposes, we enabled KYC verification which enables you to verify your user’s identification quickly with their passport, driving license, or any other government-issued documents.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="263px" height="356px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/kyc-verification.png" alt="KYC Verification" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">SMS Integration
                        </h4>
                        <p className="pharagraph">We integrate an SMS gateway so that your users can get seamless updates, OTPs, notifications, and other key announcements via SMS on their mobile phones.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="289px" height="270px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/sms-integration.png" alt="SMS Integration" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multiple Payment Methods
                        </h4>
                        <p className="pharagraph">Our pre-built software solution provides multiple payment support which helps your users to trade cryptos on their desired payment method. You can also add/remove payments as per your business needs in the future.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/multiple-payment-methods.png" alt="Multiple Payment Methods" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multiple Language Support
                        </h4>
                        <p className="pharagraph">Our white label software comes with multi-lingual support which makes it more effective for global competition and you can attract several crypto users from various parts of the world.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/multiple-language-support.png" alt="Multiple Language Support" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Online/Offline Trading
                        </h4>
                        <p className="pharagraph">Our script supports both online and offline trading. Users can trade within the platform with their convenient payment and trade options or can trade in-person offline by meeting the buyer/seller directly
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="345px" height="240px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/online-offline-trading.png" alt="Online/Offline Trading" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Messaging System
                        </h4>
                        <p className="pharagraph">We have enabled the option for users to chat between themselves during trade and can share the payment screenshot with the seller once the buyer has done the payment.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="443px" height="278px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/user-messaging-system.png" alt="User Messaging System" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Dispute Management System
                        </h4>
                        <p className="pharagraph">We have integrated a key feature in our clone script where users can directly chat with the admin or raise a ticket if there are any queries or disputes during the trade. Admin can interfere and solve the ticket after clear interrogation.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/dispute-management-system.png" alt="Dispute Management System" />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Affiliate System
                        </h4>
                        <p className="pharagraph">To enhance the number of users we have integrated an affiliate system. It helps your users to invite other crypto users to buy/sell on your platform and they can earn rewards for each referral.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="375px" height="288px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/affiliate-system.png" alt="Affiliate System" />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support System
                        </h4>
                        <p className="pharagraph">Instant support system which helps your users to raise queries and also a chat box for them to directly chat with the admin. 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="247px" height="299px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/support-systerm.png" alt="Support System" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures