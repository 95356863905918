import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Mandatory Features </span> in </span> WazirX Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Spot Trading</h4>
                <p className="pharagraph">Spot trading is one of the top features of our clone software. By using this feature, instant crypto trading can be done securely between the buyer and seller by posting buy/sell orders which will be matched by the ultra-fast matching engine.</p>
                
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/spot-trading.png" alt="Spot Trading" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="546px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/p2p-trading.png" alt="P2P Trading" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">P2P Trading
                </h4>
                <p className="pharagraph">The software we provided supports p2p trading with an escrow system that enables your users to perform safe and secure transactions. You can trade online/offline with your preferred payment modes and relevant buy/sell advertisements.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Escrow Integration
                </h4>
                <p className="pharagraph">We integrate Escrow service in our software which enables your users to trade crypto securely. Escrow-based crypto transactions will let the peer-to-peer exchange secure. Your users need not worry about losing their funds as the software comes with escrow management.
                </p>
                
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/escrow-integration.png" alt="Escrow Integration" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="550px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/high-transaction-speed.png" alt="High Transaction Speed" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">High Transaction Speed
                </h4>
                <p className="pharagraph">A super-fast transaction speed is completely possible which reduces the crypto to fiat or fiat to crypto transaction time for your users.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Multi-Cryptocurrency Acceptance
                </h4>
                <p className="pharagraph">The exchange software supports multiple digital assets for trading and also has supported major crypto trading pairs which helps your users to exchange their desired crypto in a hassle-free manner.
                </p>
                

              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="553px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/multi-cryptocurrency-acceptance.png" alt="Multi-Cryptocurrency Acceptance" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures