import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">How to get started</span> with our</span>
              Wazirx Clone Script</h3>
            <p className="pharagraph head">We follow basic and essential procedures for creating premium crypto exchange software.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/development-approach.svg" alt="Development and Approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >Our team of experts will gather & analyze the business requirements of the clientele and provide the right solution to create a crypto exchange.
            </p>
            <br />
            <h5 >Precise Planning </h5>
            <p className="pharagraph" >After collecting the requirements from the clients, our developers & designers will draft an excellent plan and execute them accordingly.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >We use advanced technology tools to design both the User and Admin dashboard UI. Our skilled designers will make it more attractive so it would be easy for both beginners and experienced traders to trade on your platform.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Our well-experienced developers will craft the powerful exchange software solution with progressive trading features as per the client’s needs.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/designing-development.svg" alt="Designing and Development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/wzrx/testing-deployment.svg" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">Our WazirX Clone Script is completely tested with advanced security tools to make sure the software is free from vulnerabilities, errors, bugs, or any other issues.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">The WazirX clone will be deployed on the client network and we make sure all the functions are working properly without any lag.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach