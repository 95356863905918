import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              WazirX Clone</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Instant Buy/Sell
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Margin Trading
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  NFT Marketplace
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  IEO Listing
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  API Documentation
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Instant Buy/Sell
                      </h4>
                      <p className="pharagraph">Our wazirX clone script comes with quick buy/sell crypto options which helps your users to trade securely and instantly with their deposited funds.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="369px" height="344px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/instant-buy-sell.png" alt="Instant Buy/Sell" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Margin Trading
                      </h4>
                      <p className="pharagraph">The margin trading feature allows your users to enhance their profit by leveraging the investment.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="414px" height="219px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/margin-trading.png" alt="Margin Trading" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">NFT Marketplace
                      </h4>
                      <p className="pharagraph">A new entry into the crypto environment where users can create and trade top NFTs in the market. A Non-Fungible Token (NFT) is a digital token that represents a real entity which can be a drawing, animated GIFs, songs, or items in video games, etc.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="367px" height="286px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/nft-marketplace.png" alt="NFT Marketplace" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">IEO Listing</h4>
                      <p className="pharagraph">IEO helps crypto startups to raise funds easily and simply. We enable the advanced IEO launchpad which helps you to attract several crypto startups who are willing to raise funds through their project.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="412px" height="267px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/ieo-launchpad.png" alt="IEO Listing" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation
                      </h4>
                      <p className="pharagraph">We provide clear API documentation that helps other platforms to connect with your exchange.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="463px" height="279px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/api-documentation.png" alt="API Documentation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon